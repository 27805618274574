<template>
  <div>
    <Sidebar />
    <div class="content-wrapper desktop-wrapper">
      <Toolbar />
      <router-view />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/menu/Sidebar.vue';
import Toolbar from '@/components/menu/Toolbar.vue';

export default {
  name: 'Layout',
  components: {
    Sidebar,
    Toolbar,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const toolbar = document.querySelector('.navbar-desktop');

      if (toolbar) {
        const top = document.body.getBoundingClientRect().top;
        toolbar.classList.add('navbar-desktop-active', top < 0);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  /*height: calc(100vh - 65px);*/
  /*overflow-y: scroll;*/
  /*left: 65px;*/
}
.v-align-top{
  vertical-align: top !important;
}
.sidebar-content-custom{
  overflow-y: hidden !important;
}
.second-toolbar a{
  visibility: hidden !important;
}

</style>
